body {
    color: #ffffff;
    margin: 0;
    padding: 0;
}

#mindprobe_home #main {
    width: 100%;
    min-height: 100vh;
    max-height: fit-content;
    background-image: url(https://lh3.googleusercontent.com/d/18IYNuBMJOWjCudUDjfwyShq5ti8sJwaF=w1920-h878-iv1);
    -webkit-animation: filter-animation 8s infinite;
    animation: filter-animation 8s infinite;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: grid;
    grid-template-rows: 90px auto;
}

@-webkit-keyframes filter-animation {
    0% {
        -webkit-filter: hue-rotate(0deg);
    }

    50% {
        -webkit-filter: hue-rotate(100deg);
    }

    100% {
        -webkit-filter: hue-rotate(0deg);
    }
}

@keyframes filter-animation {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(100deg);
    }

    100% {
        filter: hue-rotate(0deg);
    }
}

#mindprobe_home #main_nav_container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    color: #ffffff;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 80px;
    height: 70px;
}

#mindprobe_home #main_nav_logo {
    width: 200px;
    filter: invert(100%);
}

#mindprobe_home #main_nav_links {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}

#mindprobe_home #main_nav_links a {
    transition: 1s;
}

#mindprobe_home #main_nav_links a:hover {
    scale: 1.1;
}

#mindprobe_home #hamburgerIcon {
    display: none;
}

#mindprobe_home #container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 80px;
    align-items: center;
}

#mindprobe_home #line {
    background-image: linear-gradient(#ffffff00, #ffffff, #ffffff00);
    width: 2px;
    height: 500px;
}

#mindprobe_home #nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin: 0 80px 0 0;
    color: #ffffff;
}

#mindprobe_home #nav a {
    transition: 1s;
}

#mindprobe_home #nav a:hover {
    scale: 1.1;
}

#mindprobe_home #logo_nav {
    width: 100px;
    margin-bottom: 30px;
}

#mindprobe_home #content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 80px;
}

#mindprobe_home #logo_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
}

#mindprobe_home #logo_main {
    position: absolute;
    width: 35%;
}

#mindprobe_home #logo_stroke {
    filter: opacity(0.3);
    width: calc(45% + 200px);
}

#mindprobe_home #button_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    color: #ffffff;
}

#mindprobe_home #button_rr {
    text-align: center;
    line-height: 20px;
    transition: 1s;
}

#mindprobe_home #button_rr:hover {
    scale: 1.1;
}

#mindprobe_home #button_register {
    transition: 1s;
    border: 1px solid #ffffff60;
    padding: 10px 30px;
    border-radius: 15px;
}

#mindprobe_home #button_register:hover {
    scale: 1.1;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

#mindprobe_home #home_text_container {
    text-align: center;
    margin-bottom: 50px;
    color: #ffffff;
}

#mindprobe_mobile_nav_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#mindprobe_mobile_nav_items {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eeeeee;
    width: 100%;
    padding: 15px 0;
    border-radius: 15px;
}

#mindprobe_mobile_nav_items_container {
    font-size: 18px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mindprobe_mobile_nav_items_container #current {
    border: solid #00000060;
    border-width: 0 0 1px 0;
    padding: 0 0 15px 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
}

#mindprobe_mobile_nav_register_border {
    border: 1px solid #00000060;
    width: fit-content;
    padding: 12px 20px;
    border-radius: 15px;
}

@media only screen and (max-width: 1130px) {
    #mindprobe_home #nav {
        display: none;
    }

    #mindprobe_home #line {
        display: none;
    }

    #mindprobe_home #content {
        margin: 0;
    }

    #mindprobe_home #main_nav_container a {
        display: none;
    }

    #mindprobe_home #hamburgerIcon {
        display: block;
        width: 30px;
        height: 30px;
    }

    #mindprobe_home #logo_main {
        width: 50%;
    }

    #mindprobe_home #logo_stroke {
        filter: opacity(0.3);
        width: calc(60% + 200px);
    }
}

@media only screen and (max-width: 600px) {
    #mindprobe_home #home_text_container {
        font-size: 13px;
    }
}

@media only screen and (max-width: 450px) {
    #mindprobe_home #home_text_container {
        margin-bottom: 80px;
        margin-top: 20px;
    }

    #mindprobe_home #main_nav_logo {
        width: 100px;
    }

    #mindprobe_home #logo_main {
        width: 65%;
    }

    #mindprobe_home #logo_stroke {
        width: calc(75% + 200px);
    }

    #mindprobe_home #button_container {
        font-size: 13px;
    }
}

@media only screen and (max-width: 410px) {
    #mindprobe_home #container {
        padding: 0;
    }
}

@media (prefers-color-scheme: dark) {}

#mindprobe_footer {
    position: relative;
    padding: 0;
}


#mindprobe_footer #footer_background {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    background-image: url(https://lh3.googleusercontent.com/d/18IYNuBMJOWjCudUDjfwyShq5ti8sJwaF=w1920-h878-iv1);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: filter-animation 8s infinite;
    animation: filter-animation 8s infinite;
}

#mindprobe_footer #footer_wrapper {
    padding: 80px 80px 30px 80px;
}