#mindprobe_contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
}

#mindprobe_contact #title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
}

#mindprobe_contact #container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content: center;
}

#mindprobe_contact #numbers {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#mindprobe_contact #card {
    background-color: #eeeeee;
    padding: 15px 45px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mindprobe_contact #person_title{
    margin-bottom: 7px;
}

#mindprobe_contact #name {
    font-size: 20px;
    text-align: center;
}

#mindprobe_contact #number {
    font-size: 20px;
    font-weight: bold;
}

#mindprobe_contact #iframe {
    border-radius: 10px;
    width: 700px;
}

#mindprobe_contact #icon_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    justify-content: center;
    gap: 8px;
}

#mindprobe_contact #icon_container #box1{
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 50px;
    padding: 0 20px;
}

#mindprobe_contact #icon_container #box2{
    color: #25D366;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
}

#mindprobe_contact #silver_sponsor, #mindprobe_contact #bronze_sponsor, #mindprobe_contact #dmp_sponsor{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}

#mindprobe_contact #inner_logo_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#mindprobe_contact #sponsor_logo_container{
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: center;
}

#mindprobe_contact #sponsors_wrapper{
    width: 100%;
    justify-content: center;
    align-items: center;
}

#mindprobe_contact #sponsors_wrapper img{
    height: 60px;
}

#mindprobe_contact #line_sponsor {
    opacity: 30%;
    background-image: linear-gradient(#00000000, #000000, #00000000);
    width: 2px;
    height: 60px;
}

#mindprobe_contact #sponsors_wrapper #title{
    text-align: center;
    margin-top: 50px;
}

@media only screen and (max-width: 1300px) {
    #mindprobe_contact #iframe {
        width: 500px;
    }
    #mindprobe_contact #sponsors_wrapper img{
        height: 45px;
    }
}

@media only screen and (max-width: 1000px) {
    #mindprobe_contact #container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;
        justify-content: center;
    }

    #mindprobe_contact #sponsor_logo_container{
        flex-direction: column;
    }

    #mindprobe_contact #iframe {
        width: 100%;
        height: 500px;
    }

    #mindprobe_contact #sponsors_wrapper img{
        height: 60px;
    }

    #mindprobe_contact #line_sponsor {
        opacity: 30%;
        background-image: linear-gradient(90deg, #00000000, #000000, #00000000);
        width: 50%;
        height: 2px;
    }
}

@media only screen and (max-width: 750px) {
    #mindprobe_contact #container {
        width: 70%;
    }

    #mindprobe_contact #sponsors_wrapper img{
        height: 50px;
    }
}

@media only screen and (max-width: 550px) {
    #mindprobe_contact #number {
        font-size: 15px;
        font-weight: bold;
    }

    #mindprobe_contact #container {
        width: 80%;
    }

    #mindprobe_contact #iframe {
        width: 100%;
        height: 200px;
    }
    #mindprobe_contact #card {
        padding: 15px 10px;
    }

    #mindprobe_contact #inner_logo_container {
        flex-direction: column;
        align-items: center;
    }

    #mindprobe_contact #sponsors_wrapper img{
        height: 60px;
    }
}