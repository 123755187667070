@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

#brainiacBlitz_contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 0 0;
    background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_H4EnqYe-xcX6mt-0ECxgRC7DNbSjtQid2m2aVKuIMelpUCUPsQykJxmWoPWtlgsM6PxPc19YCRvtHkX7lqX24RzK3Xx8DPhrRGosTRxYWCIBLhCxxikTmIgCsVFfekkV1862UkHUQDaTG7tmVlrFJqISLKQvjasZHMDuRLsxYDQm2UQY80jV1gVamd_VGvyh91FtCychTpqebsRSGUkyzThItATtAwEf_Sh292qjiWESE5Z6DdnF7_DkN0Xjx-s_EmeNuGePIOlneRRN01rtnXFW7BVrRDePgBpYrSyxV41o5iTxGWP5w2Eys_d3PEHfjIF4b-KQQiW2j5tWM5nLiCM0lrFZL4lTho9bidmRC_ccqLqi_VmJKSiJ6X0pDXEcNMmkmXdq3YUSmnFln3Y36CxY8LKkAbNo6IQbttQGBO7Yd-1o1OMOEt0w273gPlys7wvLlHRHrTxZMjrRWdPU5YRfR7ABfOMlQUgKronnqPKabymCkN_AzKLhrzCmG6PYlp3P3lVbHpJRCFlfgZ5dxpNJKo6dLUora0M_JCfIkMdV9LWB0P5TXQF0RDwh2mHJ4Yj9a9aWIdM2MTYSFPdi0XtQT6b9HwMon7p3EWIvKTaaQ-J2JUWGK7Xs9nJbnwUdYKhX2ulR9phcD65mUnBHFaVWR8_aAVjljmOt-vMc5fO_7AwgPpQenbFhaIe4dUdXS7g-1XYQhFpL3pd-Xtbpok3b6XVFqHWsz5tDKeTa6wJwvgBfz2ECVQ24MdpK_f7VB3WFi8tMsw59JSEVi5awBKob8TWFcpIsh-f4wKiuvBpdLVDPRBkbS9mZpU4WJCtYIo5Awoh-3PlPGLYsuDcRH9P13Mn84i_ObGzM57PGVqhfscWvzwjSdGG_BOD5hhNzJu8biqO3skpqWuYxMjr2OJTsxTV09q1053WlRf5mx0PUJpOhqe9AgQY8imc4oYgBPFmz3fBvq_TTaHmLcK2O4suA2fyCufH83JJpE3p--qmU94-nPc6-zcyutV-i97bcmSsixURZrmxgfsW55vgtqUk3M_kwWfQ4QZLeW5FPUiQLqoFRPhGQJHEmVQ8Jsl-4GhfqByEu29m2QmEcsbTWvlX5Hpqfc69Lw3HdBhj5Q1oboeej730NKfRpddv6P1RVCfQY0WbdjYq0WHNJGCmAddTATpVf_NExeKM6wYkaTfZZ2aKndWSiqT9bwcqPGMT6UNKRx7A4_LpX6jKVZ-aJAMoIi8yzYobqznUFFLT6xoCzsIIaEqaoOKfwlGGGaFbQP4v0jnOTmpqXqcoAMh0DmB2fX7D2bl3WeWyC0STZhOvH2ThfZFWCa__X15_2RtIOQQA6KyJ3kyr-nwN-T5Ac7SbzfDy05T2y6kNR_K4SQCIW3Z6oPvSp5o5I_FqMexhko9s44cweZmVMOh6fBk77EeqV1H1L_HKSgLWwR9g76_1YK4t108y_TV_ulcPlKkNtZZILjW0iu5cdGFN279dGXnU-1IOOt4CUiN1q71TsY38GQqudCTJqQUBiABC_K1RbUa4EBnGuMkunoqD3-hpCmHfKOKTjvSzMr9DvJ8hQwO-2JUhuW-vZkpJ6Z0EMdOv_8b-BbUkg8a_qKyX4s5NH1fXpqPHT8tTBQHV7q0yj37TMRPun-3ISTimhRvFDFoxLqN3s3gWGsA5RbG1DahHA=w1912-h924);
    animation: backgroundMovement 40s ease infinite;
    font-family: "Josefin Sans", sans-serif;
    background-repeat: no-repeat;
    margin: 0;
    color: white;
    background-size: cover;
}

#brainiacBlitz_contact ::selection {
    color: white;
    background: #23c85d;
}

#brainiacBlitz_contact #title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

#brainiacBlitz_contact #container {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    width: 100%;
    padding: 0 10%;
}

#brainiacBlitz_contact #school {
    transition: 1s;
    padding: 50px 70px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    /* width: calc(80% - 50px); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 1px #38f77b50;
}

/* #brainiacBlitz_contact #school:hover {
    box-shadow: 0 4px 8px 0 #23c85d23, 0 4px 8px 0 #23c85d20;
} */

/* #brainiacBlitz_contact #school::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    z-index: -1;
    background: linear-gradient(40deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
} */

/* #brainiacBlitz_contact #school::after {
    transition: 1s;
    content: '';
    position: absolute;
    top: 1.2px;
    left: 1.2px;
    right: 1.2px;
    bottom: 1.2px;
    border-radius: 12px;
    background: #0A1D1B;
    z-index: -1;
} */

#brainiacBlitz_contact #card {
    background-color: #002e0f18;
    border: 1px solid #23c85d10;
    padding: 25px 45px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#brainiacBlitz_contact #details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    height: 100%;
}

#brainiacBlitz_contact #details img {
    height: 60px;
    width: fit-content;
    filter: invert(1);
}

#brainiacBlitz_contact #details h1 {
    font-size: 25px;
    z-index: -1;
    color: #ffffff80;
    /* margin-bottom: 15px; */
    text-transform: uppercase;
    font-weight: 800;
        /* border-bottom: 1px solid #23c85d; */
    transition: 1s;
    text-align: center;
    bottom: 10px;
    line-height: 30px;
    padding: 10px 0;
}

/* #brainiacBlitz_contact #details h1:hover {
    border-bottom: 1px solid #23c85d;
} */

#brainiacBlitz_contact #details p {
    font-weight: 350;
    text-align: justify;
    text-align-last: center;
    font-size: 18px;
    opacity: 80%;
}

#brainiacBlitz_contact #details #socialMediaLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
    width: 100%;
    margin-top: auto;
}

#brainiacBlitz_contact #details #socialMediaLinks a {
    padding: 15px;
    border-radius: 10px;
    font-size: 20px;
    transition: 1s;
    border: none;
    border: #23c85d15 1px solid;
}

#brainiacBlitz_contact #details #socialMediaLinks a:hover {
    background-color: #00000050;
}

#brainiacBlitz_contact #person_title {
    margin-bottom: 10px;
    font-weight: 350;
    font-size: 16px;
    opacity: 90%;
}

#brainiacBlitz_contact #name {
    font-size: 19px;
    text-align: center;
    opacity: 90%;
}

#brainiacBlitz_contact #number {
    font-size: 18px;
    font-weight: 400;
    opacity: 90%;
}

#brainiacBlitz_contact #iframe {
    border-radius: 10px;
    width: 700px;
}

#brainiacBlitz_contact #icon_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    justify-content: center;
    gap: 8px;
}

#brainiacBlitz_contact #icon_container #box1 {
    background-color: #00000040;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 60px;
    padding: 0 30px;
}

#brainiacBlitz_contact #icon_container #box2 {
    color: #25D366;
    background-color: #00000040;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
}

#silver_sponsor,
#bronze_sponsor,
#dmp_sponsor {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}

#inner_logo_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#sponsor_logo_container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: center;
}

#sponsors_wrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
}

#sponsors_wrapper img {
    height: 60px;
}

#line_sponsor {
    opacity: 30%;
    background-image: linear-gradient(#00000000, #000000, #00000000);
    width: 2px;
    height: 60px;
}

#sponsors_wrapper #title {
    text-align: center;
    margin-top: 50px;
}


#brainiacBlitz_contact #credits {
    margin-top: 80px;
    padding: 30px;
    font-weight: 350;
    border-top: 1px solid #23c85d20;
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 1350px) {
    #brainiacBlitz_contact #container {
        padding: 0 5%;
    }
}

@media only screen and (max-width: 1200px) {
    #brainiacBlitz_contact #container {
        gap: 30px;
        padding: 0 4%;
    }
}

@media only screen and (max-width: 1150px) {
    #brainiacBlitz_contact #container {
        gap: 50px;
        grid-template-rows: auto auto auto auto;
        grid-template-columns: auto;
        padding: 0 10%;
    }
}

@media only screen and (max-width: 550px) {
    #brainiacBlitz_contact #number {
        font-size: 15px;
        font-weight: bold;
    }

    #brainiacBlitz_contact #container {
        padding: 0 5%;
    }

    #brainiacBlitz_contact #iframe {
        width: 100%;
        height: 200px;
    }

    #brainiacBlitz_contact #card {
        padding: 15px 10px;
    }

    #inner_logo_container {
        flex-direction: column;
        align-items: center;
    }

    #sponsors_wrapper img {
        height: 60px;
    }
}

@media only screen and (max-width: 450px) {
    #brainiacBlitz_contact #number {
        font-size: 15px;
        font-weight: bold;
    }

    #brainiacBlitz_contact #school {
        padding: 50px 30px;
    }

    #brainiacBlitz_contact #details p {
        font-size: 16px;
    }

    #sponsors_wrapper img {
        height: 60px;
    }

    #brainiacBlitz_contact #details #socialMediaLinks {
        display: grid;
        grid-template-columns: auto auto;
    }

    #brainiacBlitz_contact #card {
        background-color: #002e0f18;
        border: 1px solid #23c85d10;
        padding: 20px 5px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}