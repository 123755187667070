#mindprobe_about{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 10%;
}

#mindprobe_about #title{
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
}

#mindprobe_about #description{
    text-align: justify;
    font-size: 20px;
}

#mindprobe_about #video_frame{
    width: 300px;
    height: 300px;
    border-radius: 10px;
}

#mindprobe_about #video_frame_description{
    text-align: center;
    margin-top: 10px;
}

#mindprobe_about #videos{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 50px;
}

#mindprobe_about #video{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mindprobe_about #stats{
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #eeeeee;
    padding: 20px;
    border-radius: 20px;
    gap: 25px;
}

#mindprobe_about #statCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#mindprobe_about #statNumber{
    font-size: 40px;
    font-weight: 700;
}

#mindprobeSouvenir{
    border: 1px solid #00000030;
    width: 100%;
    height: 85vh;
    border-radius: 15px;
    margin-top: 10px;
}

#mindprobe_about #souvenirPhoto{
    height: 400px;
    border-radius: 15px;
    filter: drop-shadow(rgba(0, 0, 0, 0.467) 3px 3px 5px);
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.467) 3px 3px 5px);
}

#mindprobe_about #gallery{
    height: 400px;
}

#mindprobe_about #galleryAndSouvenir{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mindprobe_about #galleryAndSouvenirContainer{
    display: flex;
    flex-direction: row;
    gap: 50px;
}

#mindprobe_about #souvenirWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

#mindprobe_about #galleryWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

#mindprobe_about #title2{
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: center;
}

@media only screen and (max-width: 400px) {
    #video_frame{
        margin-top: 20px;
        width: 250px;
        height: 250px;
    }

    #mindprobe_about #description{
        text-align: justify;
        font-size: 15px;
    }
}

@media only screen and (max-width: 830px) {
    #mindprobe_about #videos{
        flex-direction: column;
        gap: 25px;
    }
}

@media only screen and (max-width: 1270px) {
    #mindprobe_about #souvenirPhoto{
        height: 300px;
    }
    
    #mindprobe_about #gallery{
        height: 300px;
    }
}

@media only screen and (max-width: 1270px) {
    #mindprobe_about #souvenirPhoto{
        height: 250px;
    }
    
    #mindprobe_about #gallery{
        height: 250px;
    }
}

@media only screen and (max-width: 800px) {
    #mindprobe_about #souvenirPhoto{
        height: auto;
    }
    
    #mindprobe_about #gallery{
        height: auto;
    }

    #mindprobe_about #galleryAndSouvenirContainer{
        flex-direction: column;
    }

    #mindprobe_about #souvenirWrapper, #mindprobe_about #galleryWrapper{
        width: 60%;
        flex-direction: column;
    }
    
    #mindprobe_about #galleryAndSouvenirContainer a{
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 500px) {
    #mindprobe_about #stats{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 450px) {
    #mindprobe_about #souvenirWrapper, #mindprobe_about #galleryWrapper{
        width: 85%;
    }
}