@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

#brainiacBlitz_about {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: #030510;
    font-family: "Josefin Sans", sans-serif;
    background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_H4EnqYe-xcX6mt-0ECxgRC7DNbSjtQid2m2aVKuIMelpUCUPsQykJxmWoPWtlgsM6PxPc19YCRvtHkX7lqX24RzK3Xx8DPhrRGosTRxYWCIBLhCxxikTmIgCsVFfekkV1862UkHUQDaTG7tmVlrFJqISLKQvjasZHMDuRLsxYDQm2UQY80jV1gVamd_VGvyh91FtCychTpqebsRSGUkyzThItATtAwEf_Sh292qjiWESE5Z6DdnF7_DkN0Xjx-s_EmeNuGePIOlneRRN01rtnXFW7BVrRDePgBpYrSyxV41o5iTxGWP5w2Eys_d3PEHfjIF4b-KQQiW2j5tWM5nLiCM0lrFZL4lTho9bidmRC_ccqLqi_VmJKSiJ6X0pDXEcNMmkmXdq3YUSmnFln3Y36CxY8LKkAbNo6IQbttQGBO7Yd-1o1OMOEt0w273gPlys7wvLlHRHrTxZMjrRWdPU5YRfR7ABfOMlQUgKronnqPKabymCkN_AzKLhrzCmG6PYlp3P3lVbHpJRCFlfgZ5dxpNJKo6dLUora0M_JCfIkMdV9LWB0P5TXQF0RDwh2mHJ4Yj9a9aWIdM2MTYSFPdi0XtQT6b9HwMon7p3EWIvKTaaQ-J2JUWGK7Xs9nJbnwUdYKhX2ulR9phcD65mUnBHFaVWR8_aAVjljmOt-vMc5fO_7AwgPpQenbFhaIe4dUdXS7g-1XYQhFpL3pd-Xtbpok3b6XVFqHWsz5tDKeTa6wJwvgBfz2ECVQ24MdpK_f7VB3WFi8tMsw59JSEVi5awBKob8TWFcpIsh-f4wKiuvBpdLVDPRBkbS9mZpU4WJCtYIo5Awoh-3PlPGLYsuDcRH9P13Mn84i_ObGzM57PGVqhfscWvzwjSdGG_BOD5hhNzJu8biqO3skpqWuYxMjr2OJTsxTV09q1053WlRf5mx0PUJpOhqe9AgQY8imc4oYgBPFmz3fBvq_TTaHmLcK2O4suA2fyCufH83JJpE3p--qmU94-nPc6-zcyutV-i97bcmSsixURZrmxgfsW55vgtqUk3M_kwWfQ4QZLeW5FPUiQLqoFRPhGQJHEmVQ8Jsl-4GhfqByEu29m2QmEcsbTWvlX5Hpqfc69Lw3HdBhj5Q1oboeej730NKfRpddv6P1RVCfQY0WbdjYq0WHNJGCmAddTATpVf_NExeKM6wYkaTfZZ2aKndWSiqT9bwcqPGMT6UNKRx7A4_LpX6jKVZ-aJAMoIi8yzYobqznUFFLT6xoCzsIIaEqaoOKfwlGGGaFbQP4v0jnOTmpqXqcoAMh0DmB2fX7D2bl3WeWyC0STZhOvH2ThfZFWCa__X15_2RtIOQQA6KyJ3kyr-nwN-T5Ac7SbzfDy05T2y6kNR_K4SQCIW3Z6oPvSp5o5I_FqMexhko9s44cweZmVMOh6fBk77EeqV1H1L_HKSgLWwR9g76_1YK4t108y_TV_ulcPlKkNtZZILjW0iu5cdGFN279dGXnU-1IOOt4CUiN1q71TsY38GQqudCTJqQUBiABC_K1RbUa4EBnGuMkunoqD3-hpCmHfKOKTjvSzMr9DvJ8hQwO-2JUhuW-vZkpJ6Z0EMdOv_8b-BbUkg8a_qKyX4s5NH1fXpqPHT8tTBQHV7q0yj37TMRPun-3ISTimhRvFDFoxLqN3s3gWGsA5RbG1DahHA=w1912-h924);
    animation: backgroundMovement 40s ease infinite;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 10%;
    gap: 100px;
    color-scheme: dark;
}

#brainiacBlitz_about ::selection {
    color: white;
    background: #23c85d;
}

#brainiacBlitz_about #description {
    text-align: justify;
    text-align-last: center;
    font-size: 18px;
    line-height: 33px;
    /* margin: 50px 10%; */
    color: #ffffff;
    opacity: 80%;
    font-weight: 350;
}

/* #brainiacBlitz_about #description h1 {
    font-size: 500px;
    font-weight: 500;
    margin-bottom: 30px;
    -webkit-text-stroke: 0.5px #23c85d50;
    color: #00000000;
    position: absolute;
    transform: translateY(140px);
} */

@keyframes backgroundMovement {
    0% {
        background-position: left;
    }

    25% {
        background-position: right;
    }

    50% {
        background-position: center;
    }

    75% {
        background-position: right;
    }

    100% {
        background-position: left;
    }
}

@media only screen and (max-width: 1500px) {
    #brainiacBlitz_about {
        flex-direction: column;
        gap: 50px;
    }
}

@media only screen and (max-width: 400px) {
    #brainiacBlitz_about #description {
        font-size: 17px;
        line-height: 30px;
    }
}



#brainiacBlitz_timeline {
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

#brainiacBlitz_timeline #container {
    width: 700px;
}

#brainiacBlitz_timeline #container :nth-child(even) {
    float: right;
    clear: left;
}

#brainiacBlitz_timeline #container :nth-child(odd) {
    float: left;
    clear: right;
}

#brainiacBlitz_timeline #card h1 {
    font-size: 17px;
}

#brainiacBlitz_timeline #card p {
    font-weight: 300;
    font-size: 15px;
}

#brainiacBlitz_timeline #card {
    transition: 1s;
    width: 300px;
    padding: 20px 10px;
    text-align: center;
    border-radius: 12px;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#brainiacBlitz_timeline #card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    z-index: -1;
    background: linear-gradient(40deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
}

#brainiacBlitz_timeline #card::after {
    transition: 1s;
    content: '';
    position: absolute;
    top: 1.2px;
    left: 1.2px;
    right: 1.2px;
    bottom: 1.2px;
    border-radius: 12px;
    background: #0A1D1B;
    z-index: -1;
}

#brainiacBlitz_timeline #container::after {
    content: '';
    width: 1px;
    height: 455px;
    transform: translateX(49.5px);
    position: absolute;
    background-image: linear-gradient(#23c85d00, #23c85d, #23c85d, #23c85d00);
}

#brainiacBlitz_timeline #card:hover {
    box-shadow: 0 4px 8px 0 #23c85d23, 0 4px 8px 0 #23c85d20;
}

#brainiacBlitz_timeline #circle {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 10px;
    background-color: #23c85d;
}

#brainiacBlitz_timeline #container :nth-child(even) #circle {
    transform: translateX(-200px);
}

#brainiacBlitz_timeline #container :nth-child(odd) #circle {
    transform: translateX(200px);
}

@media only screen and (max-width: 920px) {
    #brainiacBlitz_timeline #container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 35px;
        transform: translateX(25px);
    }

    #brainiacBlitz_timeline #container::after {
        transform: translateX(-200px);
        height: 650px;
    }

    #brainiacBlitz_timeline #container :nth-child(odd) #circle {
        transform: translateX(-200px);
    }

    #brainiacBlitz_timeline #container :nth-child(even) {
        float: none;
        clear: none;
    }
    
    #brainiacBlitz_timeline #container :nth-child(odd) {
        float: none;
        clear: none;
    }
}

@media only screen and (max-width: 450px) {
    #brainiacBlitz_timeline #card {
        width: 250px;
    }

    #brainiacBlitz_timeline #container {
        transform: translateX(12.5px);
    }

    #brainiacBlitz_timeline #container::after {
        transform: translateX(-150px);
        height: 650px;
    }

    #brainiacBlitz_timeline #container :nth-child(odd) #circle {
        transform: translateX(-150px);
    }

    #brainiacBlitz_timeline #container :nth-child(even) #circle {
        transform: translateX(-150px);
    }

    #brainiacBlitz_timeline #container :nth-child(even) {
        float: none;
        clear: none;
    }
    
    #brainiacBlitz_timeline #container :nth-child(odd) {
        float: none;
        clear: none;
    }
}