body {
    color: #ffffff;
    margin: 0;
    padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

#brainiacBlitz_home #main {
    width: 100%;
    min-height: 100vh;
    max-height: fit-content;
    background-color: #030510;
    display: grid;
    grid-template-rows: 90px auto;
    font-family: "Josefin Sans", sans-serif;
    color-scheme: dark;
}

#brainiacBlitz_home ::selection {
    color: white;
    background: #23c85d;
}

#brainiacBlitz_home #header_background {
    width: 100%;
    min-height: 100vh;
    max-height: fit-content;
    background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_FSrINGaMLZrkUHbIraKdYeSglLdS623pMhbkCohtsBuHcFKdVitaNGTT_asfwWgCasgELfp9qvp_JmOi8r6sL1GUIZJNAo0HpcMnx_RC80eQdxnW1y4-eRR9IoQwNgFoGBV1PMT9N-aNPaUtUzppmIoRjgZfZCuV9xlID7T_3FzMEwrXEP9zUv6973m5HHkdZO3u15aSMuBptutM59wLvH8MQRpCh4FgntAQNvTBVcIOtMQv-VLol_DtpBfBhIO8plZrjFbZNTTTbtITpcPiIc4-OE2c1b7tybyE9PrMlKyxuXkPrWfZF6rkja43elvN78n4WogQOnRrbEtB95ck8CPa8SnuHzyO4DYRIK1FtH5sZRoSOg7A32TEjT0FFWRdrbNX5B8MOD27L8usitY16QWKIxavqOwrq1T7UTT1srPcQmeORK8L8VIGIJMrArfo1MWBuEUYcA0eVNikoRQc26-C58b7axPzO1UE0Dnauy1Khfxlh727k7yKY0OQVcR0DkdUNrvqV23S24h8R8LoL1wjMzQ_sv5K6hzgwPD6z5D9ci7VBL3DwuHhAdlPb67kRVNoKQZk6Qioaw-LO4V2dE5MKEoo674xzSHApPwJCautosp3X6jyuzBHCAV5kpMTJ6wn2hZ3RkcVaNdVHkng4phuuw4JeNtqa48Loid8wiGPwtn6btHTCAvSIdz0rXeHIBYqjPNxlVAAROlCzRcB7YNzEwdVGF4Q7ry0lzy9nSOu7yc1ZyEx6ErBiUwTXXTveGlsUONWylCyRxAgX-AxCDgxaIAvSePNplKre_RNA6hWbB2XYc6l2Sar1GuZHoHMy2YDfK6aQRc39P22q3FPwFy-eaK-yT3wE3z7wMIdia9D1iIuBaBhfvbFRo5mExMyoE3XlP1qKd3BsHC2KkmlqjAruhDVGYKYW1du1UeJQcBXzL75yPB728syndRi-n7ge586tGhjlY_MevV_vENMeuBk_KIAod9iYuAlfdoCIq4teDYdnb35YuEcQ8VThdJhLUlFw8d8m5-UNuBjf5zYr_k1ZQkDmijvs9IoeBwBG04F6sK-MXU9R5JHeMqO3a80Yyirmup-7_b2yGiL48QNDa1uLnboHKVpRLcitx5XfoMJBtDnyX8nBkq-0f-DnsK5gQOOx1Yd3Wsb3Xmsqng43S1QlUu0PeWX68nr0IXQvW-I1uUxsVx1CVaDVwdl0ZQPFzMmRpnb4TwAc0zGXS43OL0KtXAXr3IvqQPrMaPRa4vx5CdK134Gj4TT-aOa7DPg7mjLTN1jopapMug86Olz1OcCfK5wjDgiQDqRrFde3mRkKBMAyubRJDz-S7SxLrv5AK0w7o56g83qfU7AsCCqkLcPVLlRd6YeEhjH0cp_TGQDK60-Pk7rItprZzojDOPtQqYqMim_a7pB0QVVbXB_-gjiDZlU8rAp4mCeS-slkVfiBNC-NSUZG7G_fUFaz9N6OYdSvhyTAEiT2jbIJKxfG2tz7lbCjJxZyUbzwn1llxKeOLGbOSlXO7Bu6dC92D9JqLReFal3ygzSn0bSHpdhtygXI65Xmwt_qIDFvhMVjbbWJjiNbLB988ajRuBa9g3Q4Nsld8Giy3gxR6SriD-3gh0GMKoqD5s9gJFS3O5xBeEAMkyhXE9_SRgHSRewhYkXCjpynuwcK_w3XLCzNQAQ=w1910-h885);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 85px 85px #030510 inset;
    position: absolute;
    opacity: 90%;
}

#brainiacBlitz_home #header_overlay {
    width: 100%;
    min-height: 100vh;
    max-height: fit-content;
    background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_GNLyWrSMp4S29oYRBSbY5E4UoXrr6RJDLDs3TjrcDuxB_pox9OWzwT7UtHhuwx-QZkbkZ1iDKFlTdnmC_OjzwIiBvo_qqOpLXCQrJ4Js04ALRCQneIuadHrJDDyU49jo7rm1pyQHXNtSjlQuSSd-BeWt0jUeiXiPfCuMDDntOEZUDD7fClVycbqMY8O9CIs6GWFvhLoQttIOFIYmgmDkM8CbbMa0dVYs7UMKx4_imNumqvYdX2nXQrA6rUG-i5dORkh2EXEwYiOvQBZMhaZlRGD24VMCdq4jOLTfcf7fEjIgY10lUOC1neDp0y8mEuuvBW-fodQrW-Fo_Ujn_aRBBJnZUyJNIoXSopRHk5SgWSQRVBLxvAxeY_0Ajc_IzMf1jWOW3bn7hafEwV68y_mDUX0djkUJcYIQa84ZJ2k50Gm_vzUF_ouOxa8T6FSgnExFJY7zIiVxaI8VCguvWlZsIJp_d2wY3BZx65jhleStYoYFVA5Mw83sdzMP8KctoG4SxgToLlOGTCvEzguvjr-eiQ_-y6zFp6Uq2KqyE085AaC4GWlI_z5cB-QCsVgiex1zapMmYLL7Igv4H_NXojDnLbNah-4VWsUhc445sNR9bVL9llvmwpZ106NGTXai2iTf24u0HdnNYxYY4usk29XOCd6ZwCgBuPzifvKh_-B2jRDTjwpyFa0b4N2BS7JqQSYHbpMPu8gS8z4Lv0iPDwU-aUCgidoKhNZBxYFYZ1dB9o4j9D-omllKaiHMn3ZUMqJVqwbalTgOsCwPRLG_ly0054MgEk-QQkWBMd6L3MTIDNOU5KhmNl2lnppMDqs1W6ry7sUf4yS_OyE6JVXe_yKCKWuM5PH4XRjUs1sQTiwtpAEfnJEjuVt5FsILG6pAGO7q17jiJ0LyNN7vWbe5uvIDK_mqtEOZjGdt3snEGI56nBr_XlYJYghmnIAdfzNijHK2Z0uFVzNAWveKNBhw6FITcZviZZYi3T4jsSjeRB4NWhRZ_FcaKh8jpirFnrFWYkEOsGYXpeACIg4NDtZk7XVciMydSCYIJFj28p7rH8j2QmpBbFEj8e43K99cc7bLqxzebZE2w33hVwPn84Uksif9BNtTfCXBunVu4BcfeZMavirtXbLJEVmdbqN7ngIoOopA5YRujPE_i1-k99_ka0YXQuuBjoIGWSXAejS65YY-MDCKJPEV1ml7PEnYL-ONDhP1n5DtqcNWykPjccg2f8-S5VErKJybESUb_J2SMS7vgGxZOyvlh42kzE3d_3zTeCa7kaMSytQRkC7w-Z8_PxqycySWzwNotN93C_9RrS8NXDKCsHW5FiiZTOqxt18yUwxKOVC86ZpNLW9JITyp4xBkCD15tYbLn5PnKmyXF4egs7X1ToHo2Z0nD0VhHhkz-6zWA2OxZrv7VLBUZ9DX2iuHtCKMuxnTclO1yyhxkomviUt7zapDAydgYd3Hp-Wa-LAbyvoPh5B6loQy1DxDnULoTEEkJX57vVuraCuscssvRkRYp1fhkuDDt7_M-pfX1HwZeVWKuWp8lGELU_THaDVX00rg8DbqN93pJLhHEZZ5hAaaSIAFgjJjDkIqVmx_67ptedkyO-IRcCG2YivdOyQJlTrVCluSIctm2hREhKdETfds0H1dZKS-sYxjNmaZZZmRByHiUVpwtc6zeLWMU3_Q=w1910-h885);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom left;
    position: absolute;
    mix-blend-mode: color-dodge;
    opacity: 40%;
    filter: contrast(1.01);
}

#brainiacBlitz_home #main_nav_container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    color: #ffffff;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 80px;
    height: 70px;
    z-index: 1;
}

#brainiacBlitz_home #main_nav_logo {
    display: flex;
    gap: 20px;
    height: 50px;
    filter: invert(100%);
}

#brainiacBlitz_home #main_nav_links {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 350;
}

#brainiacBlitz_home #main_nav_links a {
    transition: 1s;
    text-transform: uppercase;
}

#brainiacBlitz_home #main_nav_links a:hover {
    scale: 1.1;
}

#brainiacBlitz_home #hamburgerIcon {
    display: none;
    font-size: 20px;
}

#brainiacBlitz_home #container {
    width: 100%;
    display: grid;
    grid-template-rows: auto 60px;
    gap: 20px;
    padding: 50px 80px;
    z-index: 1;
}

#brainiacBlitz_home #line {
    background-image: linear-gradient(#ffffff00, #ffffff, #ffffff00);
    width: 2px;
    height: 500px;
}

#brainiacBlitz_home #nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin: 0 80px 0 0;
    color: #ffffff;
}

#brainiacBlitz_home #nav a {
    transition: 1s;
}

#brainiacBlitz_home #nav a:hover {
    scale: 1.1;
}

#brainiacBlitz_home #logo_nav {
    width: 100px;
    margin-bottom: 30px;
}

/* #brainiacBlitz_home #content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 80px;
} */

#brainiacBlitz_home #logo_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#brainiacBlitz_home #logo_main {
    width: 55%;
}

/* #brainiacBlitz_home #logo_stroke {
    filter: opacity(0.3);
    width: calc(45% + 200px);
} */

#brainiacBlitz_home #button_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;
    color: #ffffff;
    font-size: 14px;
    bottom: 12px;
    width: fit-content;
    margin: auto;
}

#brainiacBlitz_home #button_rr {
    text-align: center;
    line-height: 18px;
    transition: 1s;
    font-size: 14px;
}

#brainiacBlitz_home #button_rr:hover {
    scale: 1.1;
}

#brainiacBlitz_home #button_register {
    transition: 1s;
    padding: 12px 35px;
    border-radius: 12px;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    overflow: hidden;
    font-size: 14px;
}

#brainiacBlitz_home #button_register::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    z-index: -1;
    background: linear-gradient(40deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
}

#brainiacBlitz_home #button_register::after {
    transition: 1s;
    content: '';
    position: absolute;
    top: 1.2px;
    left: 1.2px;
    right: 1.2px;
    bottom: 1.2px;
    border-radius: 12px;
    background: #001a09;
    z-index: -1;
}

#brainiacBlitz_home #button_register:hover {
    scale: 1.1;
}

#brainiacBlitz_home #button_register:hover::before {
    animation: hoverAnimation 1s ease forwards;
}

@keyframes hoverAnimation {
    0% {
        background: linear-gradient(40deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    5% {
        background: linear-gradient(31deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    10% {
        background: linear-gradient(22deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    15% {
        background: linear-gradient(13deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    20% {
        background: linear-gradient(4deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    25% {
        background: linear-gradient(175deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    30% {
        background: linear-gradient(166deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    35% {
        background: linear-gradient(157deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    40% {
        background: linear-gradient(148deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    45% {
        background: linear-gradient(139deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    50% {
        background: linear-gradient(120deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    55% {
        background: linear-gradient(111deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    60% {
        background: linear-gradient(102deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    65% {
        background: linear-gradient(93deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    70% {
        background: linear-gradient(84deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    75% {
        background: linear-gradient(75deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    80% {
        background: linear-gradient(66deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    85% {
        background: linear-gradient(57deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    90% {
        background: linear-gradient(48deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    95% {
        background: linear-gradient(44deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }

    100% {
        background: linear-gradient(40deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
    }
}

#brainiacBlitz_home #home_text_container {
    text-align: center;
    margin-bottom: 50px;
    color: #ffffff;
}

#brainiacBlitz_mobile_nav_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#brainiacBlitz_mobile_nav_header img {
    width: 120px;
    filter: invert(1);
}

#brainiacBlitz_mobile_nav_items {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #03051050;
    color: white;
    width: 100%;
    padding: 15px 0;
    border-radius: 15px;
    max-width: 700px;
}

#brainiacBlitz_mobile_nav_items_container {
    font-size: 18px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_H4EnqYe-xcX6mt-0ECxgRC7DNbSjtQid2m2aVKuIMelpUCUPsQykJxmWoPWtlgsM6PxPc19YCRvtHkX7lqX24RzK3Xx8DPhrRGosTRxYWCIBLhCxxikTmIgCsVFfekkV1862UkHUQDaTG7tmVlrFJqISLKQvjasZHMDuRLsxYDQm2UQY80jV1gVamd_VGvyh91FtCychTpqebsRSGUkyzThItATtAwEf_Sh292qjiWESE5Z6DdnF7_DkN0Xjx-s_EmeNuGePIOlneRRN01rtnXFW7BVrRDePgBpYrSyxV41o5iTxGWP5w2Eys_d3PEHfjIF4b-KQQiW2j5tWM5nLiCM0lrFZL4lTho9bidmRC_ccqLqi_VmJKSiJ6X0pDXEcNMmkmXdq3YUSmnFln3Y36CxY8LKkAbNo6IQbttQGBO7Yd-1o1OMOEt0w273gPlys7wvLlHRHrTxZMjrRWdPU5YRfR7ABfOMlQUgKronnqPKabymCkN_AzKLhrzCmG6PYlp3P3lVbHpJRCFlfgZ5dxpNJKo6dLUora0M_JCfIkMdV9LWB0P5TXQF0RDwh2mHJ4Yj9a9aWIdM2MTYSFPdi0XtQT6b9HwMon7p3EWIvKTaaQ-J2JUWGK7Xs9nJbnwUdYKhX2ulR9phcD65mUnBHFaVWR8_aAVjljmOt-vMc5fO_7AwgPpQenbFhaIe4dUdXS7g-1XYQhFpL3pd-Xtbpok3b6XVFqHWsz5tDKeTa6wJwvgBfz2ECVQ24MdpK_f7VB3WFi8tMsw59JSEVi5awBKob8TWFcpIsh-f4wKiuvBpdLVDPRBkbS9mZpU4WJCtYIo5Awoh-3PlPGLYsuDcRH9P13Mn84i_ObGzM57PGVqhfscWvzwjSdGG_BOD5hhNzJu8biqO3skpqWuYxMjr2OJTsxTV09q1053WlRf5mx0PUJpOhqe9AgQY8imc4oYgBPFmz3fBvq_TTaHmLcK2O4suA2fyCufH83JJpE3p--qmU94-nPc6-zcyutV-i97bcmSsixURZrmxgfsW55vgtqUk3M_kwWfQ4QZLeW5FPUiQLqoFRPhGQJHEmVQ8Jsl-4GhfqByEu29m2QmEcsbTWvlX5Hpqfc69Lw3HdBhj5Q1oboeej730NKfRpddv6P1RVCfQY0WbdjYq0WHNJGCmAddTATpVf_NExeKM6wYkaTfZZ2aKndWSiqT9bwcqPGMT6UNKRx7A4_LpX6jKVZ-aJAMoIi8yzYobqznUFFLT6xoCzsIIaEqaoOKfwlGGGaFbQP4v0jnOTmpqXqcoAMh0DmB2fX7D2bl3WeWyC0STZhOvH2ThfZFWCa__X15_2RtIOQQA6KyJ3kyr-nwN-T5Ac7SbzfDy05T2y6kNR_K4SQCIW3Z6oPvSp5o5I_FqMexhko9s44cweZmVMOh6fBk77EeqV1H1L_HKSgLWwR9g76_1YK4t108y_TV_ulcPlKkNtZZILjW0iu5cdGFN279dGXnU-1IOOt4CUiN1q71TsY38GQqudCTJqQUBiABC_K1RbUa4EBnGuMkunoqD3-hpCmHfKOKTjvSzMr9DvJ8hQwO-2JUhuW-vZkpJ6Z0EMdOv_8b-BbUkg8a_qKyX4s5NH1fXpqPHT8tTBQHV7q0yj37TMRPun-3ISTimhRvFDFoxLqN3s3gWGsA5RbG1DahHA=w1912-h924);
    animation: backgroundMovement 40s ease infinite;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
}

#brainiacBlitz_mobile_nav_items_container #current {
    border: solid #ffffff60;
    border-width: 0 0 1px 0;
    padding: 0 0 15px 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
}

#brainiacBlitz_mobile_nav_register_border {
    border: 1px solid #ffffff60;
    width: fit-content;
    padding: 12px 30px;
    border-radius: 15px;
}

@media only screen and (max-width: 1130px) {
    #brainiacBlitz_home #nav {
        display: none;
    }

    #brainiacBlitz_home #line {
        display: none;
    }

    #brainiacBlitz_home #content {
        margin: 0;
    }

    #brainiacBlitz_home #main_nav_container a {
        display: none;
    }

    #brainiacBlitz_home #hamburgerIcon {
        display: block;
        width: 30px;
        height: 30px;
    }

    #brainiacBlitz_home #logo_main {
        width: 80%;
    }

    #brainiacBlitz_home #header_background {
        box-shadow: 0 0 65px 65px #030510 inset;
    }
}

@media only screen and (max-width: 900px) {
    #brainiacBlitz_home #home_text_container {
        font-size: 13px;
    }

    #brainiacBlitz_home #logo_main {
        width: 85%;
    }

    #brainiacBlitz_home #header_background {
        box-shadow: none;
    }

    #brainiacBlitz_home #container {
        padding: 50px 50px;
    }
}

@media only screen and (max-width: 600px) {
    #brainiacBlitz_home #home_text_container {
        font-size: 13px;
    }

    #brainiacBlitz_home #logo_main {
        width: 85%;
    }
}

@media only screen and (max-width: 450px) {
    #brainiacBlitz_home #home_text_container {
        margin-bottom: 80px;
        margin-top: 20px;
    }

    #brainiacBlitz_home #main_nav_logo {
        height: 40px;
        gap: 10px;
    }

    #brainiacBlitz_home #logo_main {
        width: 90%;
    }

    #brainiacBlitz_home #button_container {
        font-size: 13px;
    }

    #brainiacBlitz_home #main_nav_container {
        padding: 0 40px;
    }
}

@media only screen and (max-width: 410px) {
    #brainiacBlitz_home #container {
        padding: 0 0 20px 0;
    }
}

@media (prefers-color-scheme: dark) {}

#mindprobe_footer {
    position: relative;
    padding: 0;
}


#mindprobe_footer #footer_background {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    background-image: url(https://lh3.googleusercontent.com/d/18IYNuBMJOWjCudUDjfwyShq5ti8sJwaF=w1920-h878-iv1);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: filter-animation 8s infinite;
    animation: filter-animation 8s infinite;
}

#mindprobe_footer #footer_wrapper {
    padding: 80px 80px 30px 80px;
}