#mindprobe_rulesAndRegulations {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 10%;
}

#mindprobe_rulesAndRegulations #title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    line-height: 50px;
}

#mindprobe_rulesAndRegulations h2 {
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0;
}

#mindprobe_rulesAndRegulations p,
#mindprobe_rulesAndRegulations ul,
#mindprobe_rulesAndRegulations ol {
    font-size: 18px;
    line-height: 28px;
}

#mindprobe_rulesAndRegulations ul,
#mindprobe_rulesAndRegulations ol {
    margin-left: 10%;
}

#mindprobe_rulesAndRegulations #special_round {
    font-size: 15px;
    background-color: greenyellow;
    padding: 4px 10px;
    border-radius: 10px;
}

#mindprobe_rulesAndRegulations #highlight {
    font-weight: 800;
}

@font-face {
    font-family: 'Sinhala Font';
    src: url("../../Services/FM-MALIT_0.TTF");
}

#mindprobe_rulesAndRegulations #sinhala_rules {
    margin-top: 50px;
    padding: 20px 30px;
    font-size: 18px;
    font-family: "Sinhala Font";
}

#mindprobe_rulesAndRegulations #contact_button{
    background-color: #eeeeee;
    padding: 10px;
    border-radius: 10px;
    border: solid 1px #00000050;
    line-height: 50px;
}