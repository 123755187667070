@import url('http://fonts.cdnfonts.com/css/trajan-pro');

::-webkit-scrollbar {
  width: 8px;
  background: black;
}

::-webkit-scrollbar-thumb {
    background-color: gray;
}

::-webkit-scrollbar:horizontal{
  height: 7px;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Imperator';
  src: url("./Services/Imperator.ttf");
}

@font-face {
  font-family: 'Rollbox';
  src: url("./Services/RollboxRegular.ttf");
}

.mindProb {
    font-family: 'Rollbox';
    color: rgb(123,17,19);
    font-size: 30px;
    margin-top: 50px;
    text-align: center;
}

#results {
  padding: 0 15px;
}

@media only screen and (max-width: 500px) {
    .groupPhoto {
      display:none;
    }
    #results {
      padding: 0;
    }
}

@media only screen and (max-width: 500px) {
  .mindProb {
    font-size:20px;
  }
}