#brainiacBlitz_rulesAndRegulations {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 10%;
    background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_H4EnqYe-xcX6mt-0ECxgRC7DNbSjtQid2m2aVKuIMelpUCUPsQykJxmWoPWtlgsM6PxPc19YCRvtHkX7lqX24RzK3Xx8DPhrRGosTRxYWCIBLhCxxikTmIgCsVFfekkV1862UkHUQDaTG7tmVlrFJqISLKQvjasZHMDuRLsxYDQm2UQY80jV1gVamd_VGvyh91FtCychTpqebsRSGUkyzThItATtAwEf_Sh292qjiWESE5Z6DdnF7_DkN0Xjx-s_EmeNuGePIOlneRRN01rtnXFW7BVrRDePgBpYrSyxV41o5iTxGWP5w2Eys_d3PEHfjIF4b-KQQiW2j5tWM5nLiCM0lrFZL4lTho9bidmRC_ccqLqi_VmJKSiJ6X0pDXEcNMmkmXdq3YUSmnFln3Y36CxY8LKkAbNo6IQbttQGBO7Yd-1o1OMOEt0w273gPlys7wvLlHRHrTxZMjrRWdPU5YRfR7ABfOMlQUgKronnqPKabymCkN_AzKLhrzCmG6PYlp3P3lVbHpJRCFlfgZ5dxpNJKo6dLUora0M_JCfIkMdV9LWB0P5TXQF0RDwh2mHJ4Yj9a9aWIdM2MTYSFPdi0XtQT6b9HwMon7p3EWIvKTaaQ-J2JUWGK7Xs9nJbnwUdYKhX2ulR9phcD65mUnBHFaVWR8_aAVjljmOt-vMc5fO_7AwgPpQenbFhaIe4dUdXS7g-1XYQhFpL3pd-Xtbpok3b6XVFqHWsz5tDKeTa6wJwvgBfz2ECVQ24MdpK_f7VB3WFi8tMsw59JSEVi5awBKob8TWFcpIsh-f4wKiuvBpdLVDPRBkbS9mZpU4WJCtYIo5Awoh-3PlPGLYsuDcRH9P13Mn84i_ObGzM57PGVqhfscWvzwjSdGG_BOD5hhNzJu8biqO3skpqWuYxMjr2OJTsxTV09q1053WlRf5mx0PUJpOhqe9AgQY8imc4oYgBPFmz3fBvq_TTaHmLcK2O4suA2fyCufH83JJpE3p--qmU94-nPc6-zcyutV-i97bcmSsixURZrmxgfsW55vgtqUk3M_kwWfQ4QZLeW5FPUiQLqoFRPhGQJHEmVQ8Jsl-4GhfqByEu29m2QmEcsbTWvlX5Hpqfc69Lw3HdBhj5Q1oboeej730NKfRpddv6P1RVCfQY0WbdjYq0WHNJGCmAddTATpVf_NExeKM6wYkaTfZZ2aKndWSiqT9bwcqPGMT6UNKRx7A4_LpX6jKVZ-aJAMoIi8yzYobqznUFFLT6xoCzsIIaEqaoOKfwlGGGaFbQP4v0jnOTmpqXqcoAMh0DmB2fX7D2bl3WeWyC0STZhOvH2ThfZFWCa__X15_2RtIOQQA6KyJ3kyr-nwN-T5Ac7SbzfDy05T2y6kNR_K4SQCIW3Z6oPvSp5o5I_FqMexhko9s44cweZmVMOh6fBk77EeqV1H1L_HKSgLWwR9g76_1YK4t108y_TV_ulcPlKkNtZZILjW0iu5cdGFN279dGXnU-1IOOt4CUiN1q71TsY38GQqudCTJqQUBiABC_K1RbUa4EBnGuMkunoqD3-hpCmHfKOKTjvSzMr9DvJ8hQwO-2JUhuW-vZkpJ6Z0EMdOv_8b-BbUkg8a_qKyX4s5NH1fXpqPHT8tTBQHV7q0yj37TMRPun-3ISTimhRvFDFoxLqN3s3gWGsA5RbG1DahHA=w1912-h924);
    animation: backgroundMovement 40s ease infinite;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
}

#brainiacBlitz_rulesAndRegulations #title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    line-height: 50px;
}

#brainiacBlitz_rulesAndRegulations h2 {
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0;
}

#brainiacBlitz_rulesAndRegulations p,
#brainiacBlitz_rulesAndRegulations ul,
#brainiacBlitz_rulesAndRegulations ol {
    font-size: 18px;
    line-height: 28px;
}

#brainiacBlitz_rulesAndRegulations ul,
#brainiacBlitz_rulesAndRegulations ol {
    margin-left: 10%;
}

#brainiacBlitz_rulesAndRegulations #special_round {
    font-size: 16px;
    background-color: #38f77b;
    padding: 5px 11px;
    border-radius: 10px;
    color: black;
}

#brainiacBlitz_rulesAndRegulations #highlight {
    font-weight: 800;
}

#brainiacBlitz_rulesAndRegulations #home_button {
    margin-top: 50px;
    padding: 30px 30px;
    font-size: 18px;
    background-color: #00000050;
    border: solid 1px #38f77b50;
}

#brainiacBlitz_rulesAndRegulations #contact_button{
    background-color: #00000050;
    padding: 10px;
    border-radius: 10px;
    border: solid 1px #38f77b50;
    line-height: 50px;
}