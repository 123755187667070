#mindprobe_register{
    max-width: 100%;
    position: relative;
    padding: 0;
}

#mindprobe_register #register_background{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    background-image: url(https://lh3.googleusercontent.com/d/18IYNuBMJOWjCudUDjfwyShq5ti8sJwaF=w1920-h878-iv1);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: filter-animation 8s infinite;
    animation: filter-animation 8s infinite;
}

#mindprobe_register #register_wrapper{
    padding: 50px 10px;
}

#mindprobe_register #size_control{
    max-width: 50%;
}

#mindprobe_register #btn1, #mindprobe_register #btn2{
    padding: 20px 10px;
    border-radius: 10px;
    width: 100%;
}

#mindprobe_register #btn1{
    color: gray;
    opacity: 0.4;
}

#mindprobe_register .btn_style1{
    background-color: #eeeeee;
    color: #000000;
    border: 1px #00000030 solid;
}

#mindprobe_register .btn_style2{
    background-image: linear-gradient(90deg, #000000, #444444);
    background-color: #000000;
    color: #ffffff;
}

#mindprobe_register #placeHolder{
    position: absolute;
    opacity: 0;
}

#mindprobe_register #btn_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#mindprobe_register #action_btn_container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: right;
}

#mindprobe_register #action_btn_container Button{
    padding: 20px 30px;
}

#mindprobe_register #links_registartion_form{
    color: blue;
}

@media only screen and (max-width: 1600px) {
    #mindprobe_register #size_control{
        max-width: 70%;
    }
}

@media only screen and (max-width: 1000px) {
    #mindprobe_register #size_control{
        max-width: 80%;
    }
}

@media only screen and (max-width: 700px) {
    #mindprobe_register #size_control{
        max-width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    #mindprobe_register #btn_container{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media only screen and (max-width: 390px) {
    #mindprobe_register #size_control{
        padding: 10px;
    }
}