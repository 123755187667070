@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

#brainiacBlitz_register {
    max-width: 100%;
    position: relative;
    padding: 0;
    color: white;
    font-family: "Josefin Sans", sans-serif;
    color-scheme: dark;
}

#brainiacBlitz_register ::selection {
    color: white;
    background: #23c85d;
}

#brainiacBlitz_register #register_background {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    background-color: #030510;
    /* background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_FSrINGaMLZrkUHbIraKdYeSglLdS623pMhbkCohtsBuHcFKdVitaNGTT_asfwWgCasgELfp9qvp_JmOi8r6sL1GUIZJNAo0HpcMnx_RC80eQdxnW1y4-eRR9IoQwNgFoGBV1PMT9N-aNPaUtUzppmIoRjgZfZCuV9xlID7T_3FzMEwrXEP9zUv6973m5HHkdZO3u15aSMuBptutM59wLvH8MQRpCh4FgntAQNvTBVcIOtMQv-VLol_DtpBfBhIO8plZrjFbZNTTTbtITpcPiIc4-OE2c1b7tybyE9PrMlKyxuXkPrWfZF6rkja43elvN78n4WogQOnRrbEtB95ck8CPa8SnuHzyO4DYRIK1FtH5sZRoSOg7A32TEjT0FFWRdrbNX5B8MOD27L8usitY16QWKIxavqOwrq1T7UTT1srPcQmeORK8L8VIGIJMrArfo1MWBuEUYcA0eVNikoRQc26-C58b7axPzO1UE0Dnauy1Khfxlh727k7yKY0OQVcR0DkdUNrvqV23S24h8R8LoL1wjMzQ_sv5K6hzgwPD6z5D9ci7VBL3DwuHhAdlPb67kRVNoKQZk6Qioaw-LO4V2dE5MKEoo674xzSHApPwJCautosp3X6jyuzBHCAV5kpMTJ6wn2hZ3RkcVaNdVHkng4phuuw4JeNtqa48Loid8wiGPwtn6btHTCAvSIdz0rXeHIBYqjPNxlVAAROlCzRcB7YNzEwdVGF4Q7ry0lzy9nSOu7yc1ZyEx6ErBiUwTXXTveGlsUONWylCyRxAgX-AxCDgxaIAvSePNplKre_RNA6hWbB2XYc6l2Sar1GuZHoHMy2YDfK6aQRc39P22q3FPwFy-eaK-yT3wE3z7wMIdia9D1iIuBaBhfvbFRo5mExMyoE3XlP1qKd3BsHC2KkmlqjAruhDVGYKYW1du1UeJQcBXzL75yPB728syndRi-n7ge586tGhjlY_MevV_vENMeuBk_KIAod9iYuAlfdoCIq4teDYdnb35YuEcQ8VThdJhLUlFw8d8m5-UNuBjf5zYr_k1ZQkDmijvs9IoeBwBG04F6sK-MXU9R5JHeMqO3a80Yyirmup-7_b2yGiL48QNDa1uLnboHKVpRLcitx5XfoMJBtDnyX8nBkq-0f-DnsK5gQOOx1Yd3Wsb3Xmsqng43S1QlUu0PeWX68nr0IXQvW-I1uUxsVx1CVaDVwdl0ZQPFzMmRpnb4TwAc0zGXS43OL0KtXAXr3IvqQPrMaPRa4vx5CdK134Gj4TT-aOa7DPg7mjLTN1jopapMug86Olz1OcCfK5wjDgiQDqRrFde3mRkKBMAyubRJDz-S7SxLrv5AK0w7o56g83qfU7AsCCqkLcPVLlRd6YeEhjH0cp_TGQDK60-Pk7rItprZzojDOPtQqYqMim_a7pB0QVVbXB_-gjiDZlU8rAp4mCeS-slkVfiBNC-NSUZG7G_fUFaz9N6OYdSvhyTAEiT2jbIJKxfG2tz7lbCjJxZyUbzwn1llxKeOLGbOSlXO7Bu6dC92D9JqLReFal3ygzSn0bSHpdhtygXI65Xmwt_qIDFvhMVjbbWJjiNbLB988ajRuBa9g3Q4Nsld8Giy3gxR6SriD-3gh0GMKoqD5s9gJFS3O5xBeEAMkyhXE9_SRgHSRewhYkXCjpynuwcK_w3XLCzNQAQ=w1910-h885); */
    background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_EIndpaadBQHbekVxJcH5rQkEO8BWX0KfymQTF-xCfhMjEQW7s0sJrtQhJPOHoOcwCzbpuP9UjSod_iaS7U-aE_RN-oy_ny4HYJSOnYqgQ6fKzkkK_guE-9kF57xmxW3V-pmzZfyKOKWp9_duyWU2nCEo_HfvXqoTBlOtk0RUHW4OmzYoAcSOT0qTYfx_F19iyqS6_AANR85UGnHckDdkaIKXZuaEQNnMC3T9x3LRRos8z1e312S4JCUhkcm9K_Tr0CjmMdR9NwWM591xf1CJvL3nstBXvQ_QDfm9r6fqXtQG1RRJ73_oZWI_irJNY0XZDIc50dkqBEfqP9hXjrBaAOMbzoJg5kwCYJBNvSRIMz7A1RtOB0xrhjGAI7qg2-skV1yS2NFnCMqxCx7YeueX4jmP_GlrjRLvU955BcexWSbJkbF5isQds4Os0vbqYKHhGlAoTn_SMFKcN2-lnZdcD5FNf8bVl0kxEOWiNXWZBI_UpB1DYb9KZqtpaTmXKzassyPFrXT_ZEYrQmtsY0vQb-gg1n7m7HqpYl8XRxB5gW53XLJsAVdlT58G96_JPRLtYFKgArxhJemMFoNwkW5Mqq19iQRYje0L4r9HBl1DA2QSHPLQcrlCq1KX4wKeu3zf-Tdbyx0YWryEMzbjKqpYhhMiivbN8R8GBoK7boPR9XoliHQV9lJWfe08923Ce6q69JQ2mDfshVzKDNxEOKGQulqmx5IRRz9_GvJ1UqQtPyixA2aPVf9ZsJpBOO77NQ_ArQCVA26Pv1HHh-piT2YCoLGKRv-ma-3e-xOdU45RgjsDGfUlRJG88N4t4axNASY6Px0m0J3fIC1MYfSqVZI2yXwSeKsABjuuRXsogjtlwI40dmsP_ZO-FUT2tNaevXJO33t56NXsNQ20xjVHB9KDoYH_xsB8W98lcsGikdzfW1DP0gpFLK2xyrtRZ-w-esv0BhfqgksiG2dYQW_n10MPkeA11Q1cTxEvxWq6FsrS_K1eGdh7VL1eIURHg2MMW1nDhBNt1C3T2idrp2CxxQOnow61n85hrr36Bb693xLcS5QCan-Y4mfaZLgnlcKIcGe1qkVytEgHxLIIO5W0PopiXqF2rolFwkAAfeql1m3bDHmJsR8Kn4aMhXk2UMNhm6nXf56AN1GFvxRQufsfdgOcSABZd-vMerslEFxPIiDHYwmq-rfUR39Au0YOxqqE9kKSjYmWylUu_M7kIlNxQ73uYOCWWxZTcp7AglfXTBXMHbkGqrnmQCmc5SjfQM7iubk_tK3hjd8UohmNtXT8VFjvlLkkxtBdJUtSx1D3Nbvh_tfq3QUOkyXu2EU1PA9dgT3jFtg6FL1gPOvonp9xmQFcAx99HS8WmMUlx9wagic4EY_WEeQf_QvHsnikkdqLs0TUNrjSgcOdfQphJuyXaYyQrzVfF7OzTdVvp3BW8LO03ZMtMEzhgxxrewtQ8bA-uERJWJ6tn1bUZgt0dPOorAiFBlBuPLY_FLNytWVrjQjJgc5bsUf9qrzQlNUS_oqBDQm0pmSixIBKsSyMxtkDcFnkU14KkfU3lczzjfe1UqzVgbO0othcYmMoHxzd14PS3PniRpHVS_p1LLTwLi1KEjbG6uLi1QZxarpYkGXzRKAluhhiDjfBlvdo7xiV0vDmhEi_EgJWTzEzNpE5ucuP2U0A=w1912-h924);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#brainiacBlitz_register #register_wrapper {
    padding: 50px 10px;
}

#brainiacBlitz_register #size_control {
    background-color: #0A1D1B90;
    max-width: 50%;
    padding: 50px 100px;
    transition: 1s;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #ffffff15;
}

#brainiacBlitz_timeline #card {
    transition: 1s;
    width: 300px;
    padding: 20px 10px;
    text-align: center;
    border-radius: 12px;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

/* 
#brainiacBlitz_register #size_control::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    z-index: -1;
    background: linear-gradient(40deg, #23c85d, #23c85d00, #23c85d00, #23c85d);
}

#brainiacBlitz_register #size_control::after {
    transition: 1s;
    content: '';
    position: absolute;
    top: 1.2px;
    left: 1.2px;
    right: 1.2px;
    bottom: 1.2px;
    border-radius: 12px;
    background: #031a0b;
    z-index: -1;
} */

#brainiacBlitz_register #btn1,
#brainiacBlitz_register #btn2,
#brainiacBlitz_register #btn3,
#brainiacBlitz_register #btn4 {
    padding: 20px 10px;
    border-radius: 10px;
    width: 100%;
}

/* #brainiacBlitz_register #btn1{
    color: gray;
    opacity: 0.4;
} */

#brainiacBlitz_register .btn_style1 {
    color: white;
    border: 1px #23c85d30 solid;
    background-color: #00000005;
}

#brainiacBlitz_register .btn_style2 {
    /* background-image: url(https://lh3.googleusercontent.com/fife/ALs6j_FSrINGaMLZrkUHbIraKdYeSglLdS623pMhbkCohtsBuHcFKdVitaNGTT_asfwWgCasgELfp9qvp_JmOi8r6sL1GUIZJNAo0HpcMnx_RC80eQdxnW1y4-eRR9IoQwNgFoGBV1PMT9N-aNPaUtUzppmIoRjgZfZCuV9xlID7T_3FzMEwrXEP9zUv6973m5HHkdZO3u15aSMuBptutM59wLvH8MQRpCh4FgntAQNvTBVcIOtMQv-VLol_DtpBfBhIO8plZrjFbZNTTTbtITpcPiIc4-OE2c1b7tybyE9PrMlKyxuXkPrWfZF6rkja43elvN78n4WogQOnRrbEtB95ck8CPa8SnuHzyO4DYRIK1FtH5sZRoSOg7A32TEjT0FFWRdrbNX5B8MOD27L8usitY16QWKIxavqOwrq1T7UTT1srPcQmeORK8L8VIGIJMrArfo1MWBuEUYcA0eVNikoRQc26-C58b7axPzO1UE0Dnauy1Khfxlh727k7yKY0OQVcR0DkdUNrvqV23S24h8R8LoL1wjMzQ_sv5K6hzgwPD6z5D9ci7VBL3DwuHhAdlPb67kRVNoKQZk6Qioaw-LO4V2dE5MKEoo674xzSHApPwJCautosp3X6jyuzBHCAV5kpMTJ6wn2hZ3RkcVaNdVHkng4phuuw4JeNtqa48Loid8wiGPwtn6btHTCAvSIdz0rXeHIBYqjPNxlVAAROlCzRcB7YNzEwdVGF4Q7ry0lzy9nSOu7yc1ZyEx6ErBiUwTXXTveGlsUONWylCyRxAgX-AxCDgxaIAvSePNplKre_RNA6hWbB2XYc6l2Sar1GuZHoHMy2YDfK6aQRc39P22q3FPwFy-eaK-yT3wE3z7wMIdia9D1iIuBaBhfvbFRo5mExMyoE3XlP1qKd3BsHC2KkmlqjAruhDVGYKYW1du1UeJQcBXzL75yPB728syndRi-n7ge586tGhjlY_MevV_vENMeuBk_KIAod9iYuAlfdoCIq4teDYdnb35YuEcQ8VThdJhLUlFw8d8m5-UNuBjf5zYr_k1ZQkDmijvs9IoeBwBG04F6sK-MXU9R5JHeMqO3a80Yyirmup-7_b2yGiL48QNDa1uLnboHKVpRLcitx5XfoMJBtDnyX8nBkq-0f-DnsK5gQOOx1Yd3Wsb3Xmsqng43S1QlUu0PeWX68nr0IXQvW-I1uUxsVx1CVaDVwdl0ZQPFzMmRpnb4TwAc0zGXS43OL0KtXAXr3IvqQPrMaPRa4vx5CdK134Gj4TT-aOa7DPg7mjLTN1jopapMug86Olz1OcCfK5wjDgiQDqRrFde3mRkKBMAyubRJDz-S7SxLrv5AK0w7o56g83qfU7AsCCqkLcPVLlRd6YeEhjH0cp_TGQDK60-Pk7rItprZzojDOPtQqYqMim_a7pB0QVVbXB_-gjiDZlU8rAp4mCeS-slkVfiBNC-NSUZG7G_fUFaz9N6OYdSvhyTAEiT2jbIJKxfG2tz7lbCjJxZyUbzwn1llxKeOLGbOSlXO7Bu6dC92D9JqLReFal3ygzSn0bSHpdhtygXI65Xmwt_qIDFvhMVjbbWJjiNbLB988ajRuBa9g3Q4Nsld8Giy3gxR6SriD-3gh0GMKoqD5s9gJFS3O5xBeEAMkyhXE9_SRgHSRewhYkXCjpynuwcK_w3XLCzNQAQ=w1910-h885); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #ffffff;
    border: 1px #23c85d solid;
    box-shadow: 0 2px 10px 0 #23c85d20, 0 2px 10px 0 #23c85d20;
    background-color: #00000020;
}

#brainiacBlitz_register #placeHolder {
    position: absolute;
    opacity: 0;
}

#brainiacBlitz_register #btn_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

#brainiacBlitz_register #action_btn_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: right;
}

#brainiacBlitz_register #action_btn_container Button {
    padding: 20px 30px;
}

#brainiacBlitz_register #links_registartion_form {
    color: #23c85d;
}

#brainiacBlitz_register #formLabel {
    opacity: 90%;
    font-weight: 350;
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 10px;
}

#brainiacBlitz_register #formInput {
    opacity: 90%;
    font-weight: 350;
    font-size: 16px;
    background-color: #03051020;
    border: #ffffff50 1px solid;
    color: white;
    height: 50px;
}

#brainiacBlitz_register #formInput:focus-visible {
    background-color: #03051050;
    outline: 0;
    border: 1px #23c85d solid;
    box-shadow: 0 2px 10px 0 #23c85d20, 0 2px 10px 0 #23c85d20;
}

#brainiacBlitz_register #formInput:hover {
    background-color: #03051050;
}

#brainiacBlitz_register #formBtnSubmit{
    background-color: #23c85d;
    height: 50px;
}

#brainiacBlitz_register #formBtnSubmit:hover{
    background-color: #188d41;
}

@media only screen and (max-width: 1600px) {
    #brainiacBlitz_register #size_control {
        max-width: 70%;
    }
}

@media only screen and (max-width: 1000px) {
    #brainiacBlitz_register #size_control {
        max-width: 80%;
        padding: 50px;
    }
}

@media only screen and (max-width: 700px) {
    #brainiacBlitz_register #size_control {
        max-width: 90%;
        padding: 50px 20px;
    }
}

@media only screen and (max-width: 600px) {
    #brainiacBlitz_register #btn_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media only screen and (max-width: 390px) {
    #brainiacBlitz_register #size_control {
        padding: 50px 10px;
    }
}